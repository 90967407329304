.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
  background: var(--main-color) !important;
}

.swal2-progress-steps .swal2-progress-step,
.swal2-progress-steps .swal2-progress-step-line,
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step-line,
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step {
  background: var(--main-color-transparent) !important;
}

#swal-place-order {
  text-transform: capitalize;

  h5 {
    font-size: 1.5rem;
    text-align: left;
    padding: 20px 0 10px;
  }

  .order-base p {
    text-align: left;
    font-size: 1.2rem;
  }

  .order-base span {
    text-align: right;
    font-weight: 500;
  }

  .order-headboard p {
    text-align: left;
    font-size: 1.2rem;
  }

  .order-headboard span {
    text-align: right;
    font-weight: 500;
  }

  .order-bedfeet p {
    text-align: left;
    font-size: 1.2rem;
  }

  .order-bedfeet span {
    text-align: right;
    font-weight: 500;
  }
}

body > div.swal2-container.swal2-center.swal2-noanimation > div {
  background-color: #fffffff2;
}
