#input-container,
#input-container-set {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 410px;

  > div {
    display: flex;
    min-width: 120px;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    position: relative;

    #input-dithesios-checkbox,
    #input-trithesios-checkbox {
      position: absolute;
      top: 4px;
      left: 10px;
      width: 20px;
    }

    > input {
      width: 80%;
      border-radius: 15px;
      border: 1px solid black;
      padding: 5px;
    }
  }
}

#dimensions-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and ($layout-bk-max-width > width > 650px) {
  #input-container,
  #input-container-set {
    flex-direction: row;
    min-width: 200px;
    justify-content: space-between;
  }
}
