.loader-backdrop::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0 0 0 / 50%);
  content: "";
  height: 100%;
  z-index: 200;
}

.loader {
  width: 45px;
  height: 45px;
  display: inline-block;
  padding: 0;
  border-radius: 100%;
  border: 5px solid;
  border-color: rgba(246 36 89/ 100%) rgba(255 255 255/ 30%) rgba(246 36 89/ 100%) rgba(255 255 255/ 30%);
  animation: loader4 1s ease-in-out infinite;
  z-index: 1000;
  position: absolute;
  top: 30%;
  right: 40%;
}

.add-to-cart-loader {
  right: 41px;
  width: 25px;
  height: 25px;
  border-color: rgb(255 255 255) rgba(255, 255, 255, 30%) rgb(255 255 255) rgba(255, 255, 255, 30%);
  margin-top: -2px;

  &.hidden {
    display: none;
  }
}

@keyframes loader4 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
