@import "globals";

$h-red: 1;
$s-grey: 0%;
$l-black: 0%;
$l-white: 100%;

#tivoli-subcategories {
  .menu-item {
    margin-top: 7px;
  }

  img {
    max-width: 70px;
    margin-top: 10px;
    margin-bottom: 21px;
  }

  .menu-item.active {
    margin-top: 0;

    img {
      margin-bottom: 10px;
    }

    span {
      background-color: var(--main-color);
      color: var(--active-letters);
      height: fit-content;
      width: 40px;
      padding: 4px 8px;
      border-radius: 10px;
    }
  }
}

#selections-container {
  position: absolute;
  bottom: 65px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 100px;
  background: #ededed;
  overflow: auto;

  #layout-controls {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      filter: invert(1);
      width: 50%;
      max-width: 34px;
      height: auto;

      @media (hover: hover) {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  #user-selections {
    width: 90%;
    min-width: 90%;
    display: flex;
    align-items: center;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 5px;
    }

    .layout-selection {
      min-width: 25%;
      width: 25%;

      @media (width > 1201px) {
        min-width: 40%;
        width: 40%;
      }

      height: 100%;
      margin: 0 1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .img-container {
        position: relative;
        border-radius: 5px;
        width: 165px;
        height: 81px;
        display: flex;
        align-items: center;

        .remove-selection {
          position: absolute;
          width: fit-content;
          right: 0;
          top: 0;
          border-radius: 5px;

          @media (width > $layout-bk-max-width) {
            top: 8px;
          }
        }

        img {
          width: 165px;
          height: 81px;
          padding: 2px;
          border-radius: 6px;
          cursor: pointer;

          @media (width > $layout-bk-max-width) {
            width: 100% !important;
            height: auto !important;
          }

          @media (width < 580px) {
            width: 100%;
            height: auto;
          }
        }

        @media (width < 580px) {
          width: 100%;
          height: auto;
        }
      }

      &.active img {
        border: 2px solid var(--main-color);
      }
    }
  }

  @media (width > $layout-bk-max-width) {
    width: 410px;
    left: -150px;
    background-color: $background-gray;
    border-radius: 5px;
  }
}

.user-selection-text {
  font-size: 0.8em;
  text-align: center;
  width: 90%;
  height: 60%;
  position: absolute;
  color: #000;
  padding: 3%;
  margin: 0;
  top: calc(50% - 35px);

  @media (width > $layout-bk-max-width) {
    margin-bottom: 13px;
  }
}
