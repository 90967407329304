@import "virtual-select-plugin/src/sass/styles";
@import "media-strom-menu";
@import "pdf_save_buttons";
@import "./placeOrder.scss";
@import "globals";
@import "selections";
@import "rightClickEditMenu";
@import "input-width-length";

$body-color: #fff;
$image-background-size: contain;
$image-background-position: center;
$image-background-repeat: no-repeat;

body {
  color: $body-color;
  font-family: sans-serif;
  height: 100vh;
  overflow: hidden;
}

main {
  height: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $layout-bk-max-width) {
    align-items: center;
  }

  @media screen and (min-width: $layout-bk-max-width) {
    align-items: normal;
  }
}

#logo {
  position: absolute;
  width: 20%;
  z-index: 90;
  top: 3%;
  right: 3%;
  max-width: 200px;
  min-width: 200px;
  border-radius: 8px;
}

#back-to-site-link {
  all: unset;
  display: flex;
  text-align: right;
  cursor: pointer;
  margin: auto;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (max-width: $layout-bk-max-width) {
    img {
      width: 115px;
    }
  }
}

@media screen and (max-width: $layout-bk-max-width) {
  #back-to-site {
    font-size: 11px;
  }
}

#back-to-site {
  display: block;
}

input[type="checkbox"] {
  filter: hue-rotate(140deg) saturate(200%) brightness(90%);
}

.mc-builder-buttons {
  position: absolute;
  right: 2%;
  z-index: 900;
  background-color: #f1f4f2;
  margin-top: 5px;
  border-radius: 15px;
  text-align: center;
  width: 90px;
  font-size: 12px;
  cursor: pointer;
  line-height: 13px;
  padding: 7px;
}

#show-build-tools {
  top: 50px;
}

#update-current-db-layout {
  top: 100px;
}

#show-price-debug-info {
  top: 167px;
}

#images-container {
  flex: 0 0 56.25vw;
  position: relative;
  min-height: 100%;

  @media screen and (min-width: $layout-bk-max-width) and (max-height: 924px) {
    min-height: 80%;
  }

  @media screen and (min-width: $layout-bk-max-width) and (max-height: 1171px) {
    min-height: 70%;
  }

  @media screen and (min-width: $layout-bk-max-width) and (max-height: 1340px) {
    min-height: 63%;
  }

  @media screen and (min-width: $layout-bk-max-width) and (max-height: 1470px) {
    min-height: 65%;
  }

  @media screen and (min-height: 1471px) {
    min-height: 100%;
  }

  @media (min-width: $layout-bk-min-width) {
    height: 100%;
  }
}

.image-display {
  width: 100%;
  height: calc(100vh - 30px);
  background-size: contain;
  position: absolute;
  left: 0;
  border-radius: 10px;

  /* background-size: $image-background-size; */

  /* -webkit-mask-size: $image-background-size; */
  mask-size: $image-background-size;
  background-repeat: $image-background-repeat;
  mask-repeat: $image-background-repeat;

  /* -webkit-mask-repeat: $image-background-repeat; */
  background-position: $image-background-position;

  /* -webkit-mask-position: $image-background-position; */
  mask-position: $image-background-position;

  /* display */
  display: initial;

  @media (max-width: 1590px) {
    background-position-y: center;
    background-position-x: 25%;
    height: 90vh;
    top: 4vh;
  }

  @media (max-width: 1499px) {
    background-position-y: center;
    background-size: contain;
    background-position-x: 33%;
    height: 82vh;
    top: 9vh;
  }

  @media (max-width: 1284px) {
    height: 75vh;
    top: 11.5vh;
  }

  @media (max-width: $layout-bk-max-width) {
    height: 70vh;
    top: 13.5vh;
  }

  @media (max-width: 1180px) {
    height: 63vh;
    top: 17vh;
  }

  @media (max-aspect-ratio: 3/4), (max-width: $layout-bk-max-width) {
    max-height: 100%;
    background-size: cover;
    top: 0;
    max-width: 100%;
    background-position-y: 85%;
  }

  @media screen and (min-width: $layout-bk-min-width) {
    height: 100%;
    width: 80%;
    margin-left: 10%;
    background-position-y: 80%;
    border-radius: 2%;
  }
}

.container.menu-categories {
  margin: 20px 10px;
}

.menu-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: transform 0.7s;
}

@media (min-width: 900px) {
  @media (hover: hover) {
    .menu-item:hover {
      scale: 1.2;
    }
  }
}

/* media strom container */
#configurator-container {
  display: grid;
  grid-template-columns: 15% 85%;
  column-gap: 9px;
  max-width: 98vw;
  min-height: 92vh;
  position: relative;
  text-align: center;
  justify-content: center;
  align-content: space-between;

  .fixed-show-2 {
    display: block;
  }

  @media screen and (max-width: 2260px) {
    grid-template-columns: 20% 80%;
  }

  @media screen and (max-width: 1500px) {
    grid-template-columns: 25% 75%;
  }

  @media screen and (min-width: $layout-bk-min-width), (max-aspect-ratio: 3/4) {
    grid-template-columns: 100%;
    grid-template-rows: 50vh 40vh;
    row-gap: 15px;
    width: 100%;

    .fixed-show,
    .fixed-show-2 {
      width: 100%;
    }
  }

  @media (max-width: $layout-bk-max-width) and (min-width: 630px) {
    grid-template-columns: 100%;
    grid-template-rows: 40vh 50vh;
    row-gap: 15px;
    width: 100%;

    .fixed-show {
      float: left;
      width: 48%;
    }

    .fixed-show-2 {
      margin-right: 5px;
    }
  }

  @media (max-width: 630px) {
    grid-template-columns: 100%;
    grid-template-rows: 30vh 60vh;
    row-gap: 15px;
    width: 100%;

    .fixed-show,
    .fixed-show-2 {
      display: unset;
    }
  }

  @media (max-width: 430px) {
    grid-template-columns: 100%;
    grid-template-rows: 27% 71%;
    row-gap: 10px;
  }

  @media (max-width: 320px) {
    grid-template-columns: 100%;
    grid-template-rows: 20% 78%;
    row-gap: 10px;
  }
}

.grid-column2 {
  min-height: 100vh;

  @media (max-aspect-ratio: 3/4), (max-width: $layout-bk-max-width) {
    min-height: 100%;
    padding: 15px 0 0;
    grid-row: 1;
  }

  @media (min-width: $layout-bk-min-width) {
    min-height: 100%;
    grid-row: 1;
  }
}

.grid-column1 {
  overflow-x: hidden;
  box-shadow: 3px 0 0 var(--main-color-transparent), inset 0 0 1px var(--main-color);
  border-radius: 10px;
  max-width: 365px;
  min-width: 300px;
  max-height: 98vh;
  overflow-y: auto;
  padding: 15px 0;
  margin-top: 1vh;
  scrollbar-width: thin;
  scrollbar-color: var(--main-color) var(--main-color-transparent) !important;
  scroll-behavior: smooth;

  @media (max-aspect-ratio: 3/4), (max-width: $layout-bk-max-width) {
    grid-row: 2;
    margin-top: inherit;
    max-width: initial;
    max-height: 47vh;
    overflow-x: hidden;
  }

  @media (max-aspect-ratio: 3/4), (max-width: 650px) {
    grid-row: 2;
    margin-top: inherit;
    max-width: initial;
    max-height: 60vh;
    overflow-x: hidden;
  }

  @media (min-width: $layout-bk-min-width) {
    margin-top: inherit;
    width: 80%;
    margin-left: 10%;
  }
}

#price-debug-info {
  display: none;
  position: absolute;
  right: 11px;
  top: 227px;
  z-index: 99;
  background-color: antiquewhite;
  width: calc(86vh - 240px);
  max-height: calc(100vh - 338px);
  overflow: auto;
}

#price-debug-info.show {
  display: initial;
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.loading-blur {
  filter: blur(0.2rem) brightness(0.9) saturate(0.9);
}

.images-loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: var(--main-color);
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  display: block;
  position: absolute;
  top: -50%;
  right: -100%;
  left: -100%;
  bottom: -100%;
  margin: auto;
  z-index: 999999;

  @media (max-width: 1590px) {
    top: -100%;
  }

  @media (max-width: 1499px) {
    top: -50%;
  }

  @media (max-width: 1284px) {
    top: -50%;
  }

  @media (max-width: $layout-bk-max-width) {
    top: -100%;
  }

  @media (max-width: 1180px) {
    top: -100%;
  }

  @media (max-aspect-ratio: 3/4), (max-width: $layout-bk-max-width) {
    top: -100%;
  }

  @media screen and (min-width: $layout-bk-min-width) {
    top: -100%;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*
.images-loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  animation: rotate 1s linear infinite;
  display: block;
  position: absolute;
  top: -50%;
  right: -100%;
  left: -100%;
  bottom: -100%;
  margin: auto;
  z-index: 999999;

  @media (max-width: 1590px) {
    top: -100%;
  }

  @media (max-width: 1499px) {
    top: -50%;
  }

  @media (max-width: 1284px) {
    top: -50%;
  }

  @media (max-width: $layout-bk-max-width) {
    top: -100%;
  }

  @media (max-width: 1180px) {
    top: -100%;
  }

  @media (max-aspect-ratio: 3/4), (max-width: $layout-bk-max-width) {
    top: -100%;
  }

  @media screen and (min-width: $layout-bk-min-width) {
    top: -100%;
  }
}

.images-loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0;
  border-radius: 50%;
  border: 5px solid var(--main-color);
  animation: prix-clip-fix 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prix-clip-fix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }

  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }

  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}
*/

.active {
  cursor: pointer;
}

#footer-label {
  position: absolute;
  bottom: -30px;
  right: 0;
  border-radius: 5px;
  background-color: #545454;
  color: white;
  padding: 1px 15px;
  font-size: 12px;
  z-index: 1000;

  @media (max-width: 1370px) {
    position: fixed;
    bottom: 0;
    right: 0;
    border-radius: 10px 0 0;
    padding: 1px 8px;
  }
}
