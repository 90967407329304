$layout-bk-min-width: 1000000px;
$layout-bk-max-width: 1370px;
$max-width: 500px;
$max-width-2: 557px;
$first-page-pagebraker: 900px;
$categories-width: 100%;
$background-gray: #ededed;
$dark-background-letters: #fff;
$light-background-letters: #000;
$primary-red-mediastrom: #d22028;
$min-background-width :430px;
