#close-backgrounds {
  position: absolute;
  top: -17px;
  right: -2px;
  margin: 0;
  padding: 0;

  img {
    width: 35px;
  }
}

#backgrounds-on-off {
  background-color: transparent;
  height: inherit;
  scale: 0.9;

  img {
    height: 35px;
  }
}

#backgrounds-container {
  width: 100%;
  position: absolute;
  bottom: 10vh;
  height: 155px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: $background-gray;

  @media (width < 350px) {
    ::-webkit-scrollbar {
      height: 2px;
    }
  }

  .label {
    margin-bottom: -12px;
    scale: 0.8;
  }

  & .menu-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    background: transparent;
    width: 20%;
    height: 80%;

    img {
      max-width: 70px;
      border: 1.5px solid black;
      border-radius: 5px;
    }

    &.active img {
      border: 2px solid var(--main-color);
    }
  }

  & .menu-item > br {
    display: none;
  }

  & .menu-item > span {
    display: none;
  }

  #background-floors {
    display: flex;
    justify-content: flex-start;
    width: inherit;
    height: 25%;
    background-color: transparent;
    align-items: center;
    margin: 0;
    color: $light-background-letters;
    border: none;
    border-radius: 5px;

    & .menu-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0;
      background: transparent;
      width: 25%;
      height: 80%;
    }

    @media (width > $layout-bk-max-width) {
      background-color: $background-gray;
      height: 28%;
      padding: 4px;
    }
  }

  #background-subcategories {
    display: flex;
    justify-content: space-around;
    width: inherit;
    height: 25%;
    background-color: transparent;
    color: #000;
    border-radius: 5px;
    border: none;

    @media (width < 350px) {
      overflow: auto;
    }

    @media (width > $layout-bk-max-width) {
      background-color: $background-gray;
      height: 28%;
      padding: 4px;
    }
  }

  #floors-title {
    background: #ffffffa1;
    height: fit-content;
    width: fit-content;
    text-align: center;
    border-radius: 10px;
    font-weight: 700;
    padding: 6px;
    font-size: 13px;
    color: $light-background-letters;
    margin-left: 24px;
    margin-bottom: -12px;

    @media (width < 475px) {
      margin-left: 13px;
    }

    @media (width > $layout-bk-max-width) {
      margin-left: -5px;
    }
  }

  @media (width > $layout-bk-max-width) {
    bottom: 49px;
    margin-left: 0;
  }
}

#background-title {
  color: #000;
  background: #ffffffa1;
  height: fit-content;
  width: fit-content;
  text-align: center;
  border-radius: 10px;
  font-weight: 700;
  padding: 6px;
  font-size: 13px;
  margin-left: 12px;

  @media (width > $layout-bk-max-width) {
    margin-left: -10px;
  }

  @media (width < 475px) {
    margin: 0;
  }
}
