#context-menu-container {
  position: fixed;
  width: 130px;
  z-index: 10000;
  border-radius: 10px;
  border: 1px solid black;
  font-size: 1.2rem;
  margin: 0;
  background-color: #fff;

  #context-menu-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ul {
      width: 100%;
      padding: 0;
      margin: 2px;
      list-style-type: none;

      li {
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: 5px;
        margin: 6px 0;
      }

      li:hover {
        background-color: #e7e7e7;
        cursor: pointer;
      }
    }
  }
}

#edit-modal {
  .card {
    width: 90% !important;
    margin: 5px auto;
  }

  #non-editable-content {
    .non-editable-item-name {
      margin: 10px;
    }
  }

  .non-editable-value {
    margin-left: 60px;
  }

  #editable-content {
    .container {
      width: 95%;
    }
  }

  .form-control:focus {
    box-shadow: 0 0 0 0.25rem #d220283f;
    border-color: #fe8686;
  }

  .btn-primary {
    background-color: #d22028;
    border-color: #d22028;
  }

  #check-button-change-price {
    margin: auto;
    text-align: center;
  }

  .form-check {
    width: 90%;
    margin: auto;
    display: flex;
  }

  .form-check-input {
    margin-right: 15px;
  }

  .form-check-label {
    margin-right: auto;
  }

  input[type="checkbox"]:checked {
    background-color: #d22028;
    border-color: #d22028;
  }

  input[type="checkbox"]:focus {
    box-shadow: 0 0 0 0.25rem #d220283f;
    border-color: #d22028;
  }
}

.colored-toast.swal2-icon-success {
  background-color: #a5dc86 !important;
}

.colored-toast.swal2-icon-error {
  background-color: #f27474 !important;
}
