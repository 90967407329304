@import "./loader.scss";
@import "globals";
@import "backgrounds";

$black: #000;
$white: #fff;
$uncheckedelements: #6c706d;

div.vscomp-dropbox-container.pop-comp-wrapper.position-bottom {
  border: 1px solid #6464644f;
  border-radius: 10px;
  box-shadow: 4px 5px 4px -2px #00000052;
  padding: 3px;
}

.dimension-buttons-container {
  display: flex;

  .dimension-button {
    margin-right: 15px;
  }
}

#dimensions,
#textile {
  padding-left: 32px;
  display: flex;
  margin-bottom: 33px;
}

#dimensions {
  margin-bottom: 32px;

  @media (max-width: $layout-bk-max-width) and (min-width: 630px) {
    width: $categories-width;
    margin: 0 auto 17px;
    max-width: 456px;
  }
}

.ms-icon {
  width: 35px !important;
}

.ms-menu-label {
  color: #000;
  padding: 2px 8px;
  width: fit-content;
  height: fit-content;
  border-radius: 10px;
  margin: 5px auto;
}

.row {
  box-shadow: 2px 3px -1px 9px #00000052;

  img {
    max-height: 25px;
  }
}

.sub-category-active {
  img {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid var(--main-color);
    padding: 10px;
    width: 70px;
  }

  span {
    background-color: var(--main-color);
    font-weight: bold;
    border-radius: 10px;
    color: var(--active-letters);
    box-shadow: 4px 5px 4px -2px #00000052;
    padding: 2px 8px;
    width: fit-content;
    margin: 0 auto;
  }
}

/* main menu scss */
.main-menu {
  text-wrap: avoid;
  font-size: smaller;
  height: 100%;
  color: $uncheckedelements;
  user-select: none;

  #starting-text {
    display: flex;
    justify-content: center;

    #text-start {
      font-family: "Century Gothic", sans-serif;
      margin: 0;
      font-weight: bold;
      width: 90%;
      text-align: center;
      font-size: 0.8rem;
    }
  }

  #title-dif {
    border-bottom: 2px solid #000;
  }

  img {
    width: 70px;
    border-radius: 7px;
    height: 40px;
    object-fit: cover;
    margin: 5px auto 0;
  }

  .buttons {
    cursor: pointer;
    height: auto;
    margin-bottom: 9px;
  }

  .menu-item {
    max-height: stretch;

    .menu-item-name {
      @supports (-moz-appearance:none) {
        margin-top: 10px;
      }
    }

    &.disabled {
      pointer-events: none;
    }

    &.active img {
      background-color: #fff;
      border-radius: 10px;
      border: 1px solid var(--main-color);
      padding: 3px;
      width: 70px;
    }

    @media (max-width: 1280px) {
      margin: 0;
      padding: 0;
    }

    @media (max-width: $layout-bk-max-width) {
      margin: 1vh 0;
    }

    @media (max-width: 630px) {
      margin: 0.1em 0;
    }
  }

  /* **************************animations*************************** */
  .slide-out-left {
    animation: slide-out-left 0.2s cubic-bezier(0.895, 0.03, 0.685, 0.22) both;
  }

  @keyframes slide-out-left {
    0% {
      transform: translateZ(0) translateX(0);
      opacity: 1;
    }

    99% {
      height: initial;
    }

    100% {
      transform: translateZ(-1100px) translateX(-1000px);
      opacity: 0;
      height: 0;
      width: 0;
    }
  }

  .slide-out-right {
    animation: slide-out-right 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }

  @keyframes slide-out-right {
    0% {
      transform: translateX(0);
      opacity: 1;
    }

    99% {
      height: initial;
    }

    100% {
      transform: translateX(1000px);
      opacity: 0;
      height: 0;
      width: 0;
    }
  }

  .slide-in-right {
    animation: slide-in-right 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s both;
  }

  @keyframes slide-in-right {
    0% {
      transform: translateX(1000px);
      opacity: 0;
    }

    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .slide-in-left {
    animation: slide-in-left 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s both;
  }

  @keyframes slide-in-left {
    0% {
      transform: translateX(-1000px);
      opacity: 0;
    }

    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  /* **************************End of animations*************************** */

  .dimensions {
    color: $black;
    width: 100%;

    .dimension-button {
      cursor: pointer;
      border-radius: 10px;
      background-color: $black;
      color: $white;
      padding: 1px 4px;
    }

    .dimension-reset {
      cursor: pointer;
      height: auto;
      width: 22px;
      margin: 0;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      appearance: textfield;
      box-shadow: inset 0 0 5px #000;
      background: transparent;
    }

    @media screen and (max-width: $layout-bk-max-width) and (min-width: 630px) {
      max-width: $max-width;
    }
  }

  #breadcrumbs {
    display: none;
    color: $black;
    text-transform: capitalize;
    text-align: left;
    transform: scale(0.9);
    padding: 1rem;

    @media (max-width: 1280px) and (min-width: $layout-bk-max-width) {
      margin-bottom: 444px;
      margin-top: 12px;
    }

    @media screen and (max-width: $layout-bk-max-width) and (min-width: 580px) {
      width: 100%;
      max-width: 472px;
    }

    @media screen and (max-width: 579px) {
      width: 85%;
    }
  }

  /* Tivoli subcategories */

  input[type="checkbox"] {
    width: 1em;
  }

  #textile,
  #bed-foot {
    img {
      transform: scale(0.9);
    }
  }

  #category-d,
  #category-e {
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    width: 100%;
    max-width: 513px;

    .menu-item-name-brake {
      display: none;
    }

    .menu-item {
      display: flex;
      flex-direction: column;
      position: relative;
      margin: 4px 0;
    }

    img {
      width: 95%;
      height: 93%;
      border: 1px solid var(--main-color);
      border-radius: 33%;
      max-width: 55px;
    }

    @media (max-width: 1280px) and (min-width: $layout-bk-max-width) {
      padding: 10px 18px;
    }

    @media screen and (max-width: $layout-bk-max-width) {
      grid-template-columns: repeat(3, 1fr);
      justify-content: space-between;
    }

    @media screen and (max-width: 580px) {
      justify-content: space-between;
    }
  }

  #bed-foot {
    order: 1;
    display: flex;
  }

  #category-a {
    font-size: smaller;
    width: $categories-width;
    transition: opacity 1s;

    .menu-item {
      display: flex;
      justify-content: center;

      &.active .menu-item-name {
        background-color: var(--main-color);
        font-weight: bold;
        border-radius: 10px;
        color: var(--active-letters);
        box-shadow: 4px 5px 4px -2px #00000052;
        padding: 2px 8px;
        width: fit-content;
        margin: 0 auto;
      }

      img {
        margin-top: 14px;
        padding: 0.5em;
        width: 56px;
        background-color: #fff0;
        box-shadow: 4px 5px 4px -2px #0000;
      }
    }

    #bed-foot {
      flex-direction: column-reverse;
    }

    #check-to-connect {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    & .menu-item.active img {
      border: none;
      filter: var(--filter-main-color);
    }

    /* @media (max-width: $layout-bk-max-width) and (min-width: 630px) {
      height: 19vh !important;
    } */
  }

  #category-b {
    width: $categories-width;
    justify-content: space-evenly;
    max-width: 518px;
    padding-top: 15px;

    .menu-item {
      height: 75px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &.active span {
        background-color: var(--main-color);
        font-weight: bold;
        border-radius: 10px;
        color: #fff;
        box-shadow: 4px 5px 4px -2px #0000;
        padding: 2px 8px;
        width: fit-content;

        @supports (-moz-appearance:none) {
          margin-top: 10px;
        }
      }

      &.active img {
        background-color: #fff;
        border: 1px solid var(--main-color);
      }
    }

    @media (max-width: $layout-bk-max-width) and (min-width: 630px) {
      max-width: 514px;
      margin-top: 1.1vh;
    }
  }

  #differenciate {
    margin-top: 37px;
    display: flex;
    width: $categories-width;
    justify-content: center;
  }

  .row p {
    margin: 0;
  }

  #category-c {
    width: $categories-width;
    max-width: 530px;
    justify-content: space-evenly;

    .menu-item {
      padding: 0;
      width: fit-content;
      margin: 0 auto;
      height: fit-content;
    }

    p {
      grid-column: 1/4;
    }
  }

  #bed-attribute {
    margin-top: 30px;
    margin-bottom: 10px;
    max-width: 460px;
  }

  #bed-attribute .row > div {
    transition: transform 0.7s;
  }

  @media (hover: hover) {
    #bed-attribute .row > div:hover {
      transform: scale(1.2);
    }
  }

  #bed-feet {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    justify-content: space-evenly;
    margin: 34px auto 0;
    font-size: smaller;

    .menu-item {
      height: 80px;
      display: flex;
      flex-direction: column;
      margin-bottom: 60px;

      &.active span {
        background-color: var(--main-color);
        font-weight: bold;
        border-radius: 10px;
        color: #fff;
        box-shadow: 4px 5px 4px -2px #0000;
        padding: 2px 8px;
        width: 70px;
        margin: 0 auto 10px;

        @supports (-moz-appearance: none) {
          margin: 5px auto 10px;
        }
      }

      &.active img {
        margin: -4px auto 0;
        border: 1px solid var(--main-color);
        background-color: #fff;
      }
    }

    @media (max-width: 1280px) {
      grid-template-columns: 40% 40%;
      margin: 0 auto 35px;
    }

    @media (max-width: $layout-bk-max-width) {
      width: 100%;
      grid-template-columns: repeat(3, 20%);
      max-width: $max-width;

      .menu-item {
        margin-left: 1em;
        margin-right: 1em;
      }
    }

    @media (max-width: 620px) {
      grid-template-columns: repeat(3, 33%);
      margin: auto;
      justify-items: center;

      .menu-item {
        width: 10vh;
      }
    }

    @media (max-width: 400px) {
      grid-template-columns: repeat(3, 33%);

      .menu-item {
        width: 7vh;
      }
    }

    @media (min-width: $layout-bk-min-width) {
      width: 100% !important;
      grid-template-columns: repeat(5, 14%) !important;
    }
  }

  #property-selections {
    width: 82%;
    max-width: 394px !important;

    @media (max-width: $layout-bk-max-width) {
      width: 83%;
    }

    @media (max-width: 400px) {
      width: 79%;
    }
  }

  #properties-container {
    background-color: $background-gray;
    border-radius: 15px;
    padding: 1rem;
  }

  #category-c > .menu-item {
    &.active span {
      background-color: var(--main-color);
      font-weight: bold;
      border-radius: 10px;
      color: var(--active-letters);
      padding: 2px 8px;
    }
  }

  #category-d > .menu-item {
    &.active img {
      padding: 2px;
      background-color: #fff;
      border: 1px solid var(--main-color);
    }

    &.active span {
      background-color: var(--main-color);
      font-weight: bold;
      border-radius: 10px;
      color: var(--active-letters);
      padding: 2px 8px;
    }
  }

  .vscomp-dropbox-container {
    position: absolute;
  }

  #psalidaki {
    margin-bottom: 20px;
    width: 84%;
    max-width: 393px !important;

    @media (min-width: $layout-bk-min-width) {
      display: none;
    }

    @media (max-width: $layout-bk-max-width) {
      width: 50%;
    }

    @media (max-width: 900px) {
      width: 84%;
    }

    @media (max-width: 400px) {
      width: 79%;
    }
  }

  @media (max-width: 1260px) and (min-width: $layout-bk-max-width) {
    input {
      width: 51px;
    }
  }

  @media (max-width: $layout-bk-max-width) {
    input {
      width: stretch;
    }
  }

  @media screen and (max-width: $layout-bk-max-width) {
    min-height: unset;
    height: 90%;
  }

  @media (min-width: $layout-bk-min-width) {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;

    #category-a {
      align-content: center;
      width: $categories-width;
    }

    .fixed-show-2 {
      grid-row: 2;
      align-self: flex-start;
    }

    #category-b {
      align-items: center;

      img {
        height: 50px;
      }
    }

    #differenciate {
      display: none;
    }

    #bed-attribute {
      all: unset;
    }

    #property-selections {
      display: block;
      margin-left: 25%;
    }

    .dynamic-show,
    .dimensions,
    #bed-feet {
      grid-column: 2/3;
    }

    #category-c .menu-item {
      width: 55px;
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 20px;
  height: 10%;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--main-color-transparent);
  border-radius: 20px !important;
  height: 5%;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--main-color);
}

::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 10px;
}

::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 10px;
}

.menu-categories {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  grid-gap: 20px;
  margin: auto auto 0;
  padding: 10px;
  justify-content: center;
  overflow: hidden;

  @media (min-width: $layout-bk-min-width) {
    grid-template-columns: repeat(5, auto);
    justify-content: space-around;

    #differenciate {
      display: none;
    }
  }

  @media (max-width: 1370px) and (min-width: 630px) {
    grid-gap: 10px;
  }
}

#category-a.menu-categories {
  grid-template-columns: 50% 50%;
  justify-content: space-around;
  max-width: 509px;

  @media screen and (max-width: $layout-bk-max-width) and (min-width: 630px) {
    grid-template-columns: 50% 50%;
    align-items: center;
  }
}

/* select 2 css */

.select-wrapper {
  margin: auto;
  max-width: $max-width;
  width: calc(100% - 40px);
}

.select-pure-select {
  align-items: center;
  background: #f9f9f8;
  border-radius: 4px;
  border: 1px solid #00000001;
  box-shadow: 0 2px 4px #00000004;
  box-sizing: border-box;
  color: #363b3e;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  justify-content: left;
  min-height: 44px;
  padding: 5px 10px;
  position: relative;
  transition: 0.2s;
  width: $categories-width;
}

.select-pure-options {
  border-radius: 4px;
  border: 1px solid #00000001;
  box-shadow: 0 2px 4px #00000004;
  box-sizing: border-box;
  color: #363b3e;
  display: none;
  left: 0;
  max-height: 221px;
  position: absolute;
  top: 50px;
  width: $categories-width;
  z-index: 100;
}

.select-pure-select-opened .select-pure-options {
  display: block;
}

.select-pure-option {
  background: #fff;
  border-bottom: 1px solid #e4e4e4;
  box-sizing: border-box;
  height: 44px;
  line-height: 25px;
  padding: 10px;
}

.select-pure-option-selected {
  color: #e4e4e4;
  cursor: initial;
  pointer-events: none;
}

.select-pure-option-hidden {
  display: none;
}

.select-pure-selected-label {
  background: #5e6264;
  border-radius: 4px;
  color: #fff;
  cursor: initial;
  display: inline-block;
  margin: 5px 10px 5px 0;
  padding: 3px 7px;
}

.select-pure-selected-label:last-of-type {
  margin-right: 0;
}

.select-pure-selected-label i {
  cursor: pointer;
  display: inline-block;
  margin-left: 7px;
}

@media (hover: hover) {
  .select-pure-selected-label i:hover {
    color: #e4e4e4;
  }
}

.select-pure-autocomplete {
  background: #f9f9f8;
  border-bottom: 1px solid #e4e4e4;
  border-left: none;
  border-right: none;
  border-top: none;
  box-sizing: border-box;
  font-size: 16px;
  outline: none;
  padding: 10px;
  width: $categories-width;
}

.vscomp-wrapper.has-clear-button .vscomp-toggle-button {
  border-radius: 10px;
}

/* end of select2 css */

.hidden {
  display: none !important;
}

#dimensions-container > div.dimensions.container span.irs-bar {
  background-color: var(--main-color);
}

#dimensions-container > div.dimensions.container span.irs-single {
  background-color: var(--main-color);
}

#dimensions-container > div.dimensions.container {
  max-width: 420px !important;
  width: 89% !important;
  margin-bottom: 40px;

  @media (max-width: 400px) {
    width: 87% !important;
  }
}

#headboards-tooltip {
  background: #ededed;
  color: #000;
  border-radius: 10px;
  font-size: 0.7rem;
  padding: 4px 10px;
  width: fit-content;
  margin-top: 18px;
  margin-bottom: -22px;
}

.irs-handle {
  z-index: 0 !important;
}

.red-filter {
  filter: invert(29%) sepia(94%) saturate(7203%) hue-rotate(356deg) brightness(113%) contrast(122%);
}

#user-selections {
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.bed-text-container {
  background-color: $background-gray;
  border-radius: 10px;
  padding: 15px;
}
