@import "globals";

$bottom-right-menu-shadow: 2px 2px 2px -2px rgba(0, 0, 0, 35%);

#price-container {
  padding-top: 6px;
  width: 75%;
  display: flex;
  justify-content: space-around;
  max-width: 350px;
  align-items: center;
  margin: 0 auto;

  & div {
    & div {
      text-align: center;
      font-weight: bold;
      color: #fff;
      padding-top: 0;
      font-size: 1.2rem;
    }
  }

  /* #starting-price-container {
    display: none;
  }

  #discount-container {
    display: none;
  } */

  #starting-price,
  #price-label {
    font-size: 1.2rem;
    margin-bottom: -5px;
  }

  #starting-price {
    text-decoration: line-through;
  }

  #price-label {
    color: var(--menu-bar-text);
  }

  p {
    margin: 0;
    text-align: center;
    font-size: 0.6em;
  }

  @media (max-width: $layout-bk-max-width) {
    .add-to-cart-loader {
      right: 14%;
      top: 24%;
    }
  }

  @media (max-width: 400px) {
    font-size: 17px;
    width: 80%;

    #starting-price,
    #price-label {
      font-size: 20px;
    }

    & div {
      & div {
        font-size: 20px;
      }
    }

    #add-to-cart {
      width: 70px;
      font-size: 10px !important;
    }

    .add-to-cart-loader {
      right: 33px;
      top: 19px;
    }

    #discount-container {
      padding: 10px;
    }
  }
}

#user-buttons-container {
  /* background-color: $black; */
  height: 90px;
  width: 100px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  color: $dark-background-letters;
  align-items: flex-start;

  .wrapper {
    margin: auto 5px;
    position: absolute;
    top: 25px;
    left: 10px;
    scale: 0.9;
  }

  /* nav-icon-5 */
  .nav-icon-5 {
    width: 35px;
    height: 30px;
    margin: 0;
    position: relative;
    cursor: pointer;
    display: inline-block;

    & span {
      background-color: #fff;
      position: absolute;
      border-radius: 2px;
      transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
      width: 100%;
      height: 4px;
      transition-duration: 500ms;
    }

    & span:nth-child(1) {
      top: 0;
      left: 0;
    }

    & span:nth-child(2) {
      top: 13px;
      left: 0;
      opacity: 1;
    }

    & span:nth-child(3) {
      bottom: 0;
      left: 0;
    }

    &.open span:nth-child(1) {
      transform: rotate(45deg);
      top: 13px;
    }

    &.open span:nth-child(2) {
      opacity: 0;
    }

    &.open span:nth-child(3) {
      transform: rotate(-45deg);
      top: 13px;
    }

    @media (hover: hover) {
      &:not(.open):hover span:nth-child(1) {
        transform: rotate(-3deg) scaleY(1.1);
      }

      &:not(.open):hover span:nth-child(2) {
        transform: rotate(3deg) scaleY(1.1);
      }

      &:not(.open):hover span:nth-child(3) {
        transform: rotate(-4deg) scaleY(1.1);
      }
    }
  }

  #burger-menu {
    position: absolute;
    height: 10vh;
    bottom: 0;
    width: 100vw;
    background: #ededed;
    display: flex;
    transition: all 0.3s ease;

    #open-backgrounds-button {
      display: flex;
      width: 50%;
      justify-content: space-around;
      align-items: center;
    }

    #orders-images {
      display: flex;
      width: 50%;
      justify-content: space-around;
      align-items: center;

      input {
        height: 35px;
      }

      #form-get-pdf {
        input[type="image"] {
          filter: invert(100%);
        }
      }
    }

    img {
      height: 35px;
    }
  }

  #installments {
    display: none;
    position: absolute;
    right: 10%;
    width: 70%;
    bottom: -7px;
    justify-content: flex-start;
    flex-direction: column;
    align-items: stretch;

    p {
      margin: 0;
    }

    #installments-number {
      font-size: clamp(0.5rem, 1vw, 0.6rem);
      font-weight: bolder;
      padding: 0 8px;
    }

    #disclaimer {
      font-size: clamp(0.3rem, 1vw, 0.4rem);
      color: rgba(255, 255, 255, 70%);
      padding: 0 8px 8px;
    }
  }

  @media (width < $layout-bk-max-width) {
    left: 0;
    right: 0;
    width: 100%;
    background-color: black;
  }
}

/* Price spinner */
.price-loader {
  width: 10px;
  margin-left: 3px;
  display: block;
  height: 10px;
  border-radius: 50%;
  background-color: var(--main-color);
  box-shadow: 32px 0 var(--main-color), -32px 0 var(--main-color);
  position: relative;
  animation: flashes 0.5s ease-in-out infinite;
  margin-top: 11px;
}

@keyframes flashes {
  0% {
    background-color: #fff2;
    box-shadow: 32px 0 #fff2, -32px 0 var(--main-color);
  }

  50% {
    background-color: var(--main-color);
    box-shadow: 32px 0 #fff2, -32px 0 #fff2;
  }

  100% {
    background-color: #fff2;
    box-shadow: 32px 0 var(--main-color), -32px 0 #fff2;
  }
}

#price-label-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.loading {
    padding: 10px;
  }
}

#add-to-cart {
  display: none !important;
  cursor: pointer;
  padding: 4px;
  background: var(--main-color);
  color: #fff;
  height: 40px;
  width: 87px;
  font-size: 11px !important;
  text-align: center;
  font-weight: bold;

  &.loading {
    color: transparent;
  }
}

@media (width > $layout-bk-max-width) {
  #user-buttons-container {
    z-index: 150;
    background-color: $background-gray;
    height: 55px;
    bottom: 42px;
    right: 2%;
    color: $light-background-letters;
    border-radius: 5px;
    align-items: center;
    box-shadow: $bottom-right-menu-shadow;

    #burger-menu {
      animation: none;
      width: 160px;
      bottom: 0;
      right: 100%;
      height: inherit;
      background-color: transparent;
      justify-content: unset;
      margin: 0 -0.5px;

      &.hidden {
        display: flex !important;
      }

      #backgrounds-container {
        width: 320px;
        right: -5px;
        background-color: transparent;
        border-radius: 5px;

        @media (hover: hover) {
          & .menu-item:hover {
            cursor: pointer;
          }
        }

        @media (hover: hover) {
          & .menu-item:hover::before {
            border-radius: 10px;
            content: attr(data-name);
            position: absolute;
            font-size: 11px;
            color: var(--active-letters);
            background: var(--menu-bar);
            display: flex;
            justify-content: center;
            align-items: center;
            bottom: 110%;
            padding: 2px 7px;
          }
        }

        #background-subcategories {
          width: 310px;
          height: 55px;
          align-items: center;
          justify-content: space-between;
          margin-bottom: -10px;
        }

        #background-floors {
          width: 310px;
          align-items: center;
          justify-content: inherit;
        }
      }

      #open-backgrounds-button {
        display: flex;
        height: inherit;
        background-color: $background-gray;
        width: 50%;
        border-radius: 5px;
        margin: auto;

        button {
          scale: 0.7;
          width: 50%;
        }

        #backgrounds-on-off {
          padding: 0;
        }

        & #selections-img {
          padding: 0;

          img {
            height: 40px;
          }
        }

        @media (width > $layout-bk-max-width) {
          box-shadow: $bottom-right-menu-shadow;
        }
      }

      #orders-images {
        height: inherit;
        background-color: $background-gray;
        width: 50%;
        border-radius: 5px;
        margin: 0 5px;
        box-shadow: $bottom-right-menu-shadow;

        #form-get-pdf {
          padding: 0;
        }

        #form-get-image {
          padding: 0;
        }

        form {
          scale: 0.7;
          width: 50%;
        }
      }
    }

    #price-container {
      padding-top: 0;
      width: 100%;
      justify-content: space-around;

      #starting-price {
        color: $light-background-letters;
      }

      #discount {
        color: $light-background-letters;
        margin-bottom: -5px;
        margin-top: -1px;
      }
    }

    #installments {
      position: absolute;
      width: 410px;
      background: $background-gray;
      box-shadow: $bottom-right-menu-shadow;
      border-radius: 5px;
      margin-top: -15px;
      right: 0;
      flex-direction: row;
      align-items: flex-end;
      bottom: -21px;
      height: 16px;

      #installments-number {
        padding: 3px 3px 0;
      }

      #disclaimer {
        color: rgba(0, 0, 0, 90%);
        padding: 3px;
      }
    }
  }

  .wrapper {
    display: none;
  }
}
